.form-control {
  margin: 0.1rem;
  bottom: 0;
}
.add-item {
  margin: 0.5rem;
}

.form-group {
  margin: auto;
  width: 50vw;
}

.form-row {
  padding: 0;
}

.submit-form {
}