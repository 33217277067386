@import '~bootstrap/scss/bootstrap.scss';

.navbar {
  padding: 0;

  .span {
    color: white;
  }
}

.form-group {
  @media (min-width: 768px) {
    width: 50vw;
  }
}

.btn-primary {
  background: #548E1A;
  border: none;

  &:hover {
    background: #68B21E;
  }
}