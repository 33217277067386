.control-panel {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}
ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.popup {
  max-width: 75%;
}