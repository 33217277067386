.marker-popup {
  .header {
    margin: auto;
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.4rem;
  }
  .popup-content {
    font-weight: 600;
    .when {
      color: #808080;
      font-style: italic;
    }
  }
}
